<template>
  <div class="projects-group">
    <div class="container">
      <div class="project-group-items">
        <Project
          v-for="project in projects"
          :key="project.id"
          :project="project"
          @project-selected="$emit('project-selected', $event)"
          :class="{ active: activeProject && activeProject.id === project.id }"
        />
      </div>
    </div>
    <ProjectDetails
      v-if="showDetails"
      :project="activeProject"
      @close="$emit('project-selected', null)"
    />
  </div>
</template>

<script>
import Project from './Project';
import ProjectDetails from './ProjectDetails';
import some from 'lodash/some';

export default {
  components: { Project, ProjectDetails },

  props: {
    projects: { required: true, type: Array },
    activeProject: { required: false, type: Object }
  },

  computed: {
    showDetails() {
      if (!this.activeProject) return false;

      return some(this.projects, (item) => item.id === this.activeProject.id);
    }
  }
};
</script>
