<template>
  <div>
    <div v-if="projects !== null" class="projects-list">
      <ProjectsGroup
        v-for="projects in groups"
        :projects="projects"
        :key="groupKey(projects)"
        :activeProject="activeProject"
        @project-selected="activeProject = $event"
      />
    </div>
    <div v-else class="text-sm py-4">Loading...</div>
  </div>
</template>

<script>
import ProjectsGroup from './ProjectsGroup';
import chunk from 'lodash/chunk';
import find from 'lodash/find';

const breakpoints = [
  { width: 1024, cols: 3 },
  { width: 640, cols: 2 },
  { width: 0, cols: 1 }
];

export default {
  components: { ProjectsGroup },

  props: {
    category: { required: false, type: String }
  },

  data() {
    return {
      projects: null,
      activeProject: null,
      groups: []
    };
  },

  async mounted() {
    const data = await fetch(`/projects/${this.category || ''}`);
    this.projects = await data.json();

    this.groupProjects();

    window.addEventListener('resize', this.groupProjects);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.groupProjects);
  },

  methods: {
    groupProjects() {
      this.groups = chunk(this.projects, this.breakpoint().cols);
    },

    breakpoint() {
      return find(breakpoints, (item) => window.innerWidth >= item.width);
    },

    groupKey(projects) {
      return projects.map((item) => item.id).join('--');
    }
  }
};
</script>
