<template>
  <div class="ogranizer-group mb-8">
    <div class="organizes-group-items grid" :class="`grid-cols-${organizers.length}`">
      <button
        v-for="organizer in organizers"
        :key="organizer.id"
        @click="$emit('organizer-selected', organizer)"
        class="organizer mx-auto"
        :class="{ active: activeOrganizer && activeOrganizer.id === organizer.id }"
        v-html="organizer.image"
      />
    </div>

    <OrganizerDetails
      v-if="showDetails"
      :organizer="activeOrganizer"
      @close="$emit('organizer-selected', null)"
    />
  </div>
</template>

<script>
import OrganizerDetails from './OrganizerDetails';
import some from 'lodash/some';

export default {
  components: { OrganizerDetails },

  props: {
    organizers: { required: true, type: Array },
    activeOrganizer: { required: false, type: Object }
  },

  computed: {
    showDetails() {
      if (!this.activeOrganizer) return false;

      return some(this.organizers, (item) => item.id === this.activeOrganizer.id);
    }
  }
};
</script>
