<script>
export default {
  data() {
    return {
      isMobileMenuActive: false
    };
  },

  methods: {
    toggleMobileMenu() {
      this.isMobileMenuActive = !this.isMobileMenuActive;
    }
  }
};
</script>
