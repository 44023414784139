import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueScrollTo from 'vue-scrollto';

import Header from '../application/components/Header';
import Organizers from '../application/components/Organizers/Organizers';
import PastStreams from '../application/components/PastStreams';
import Photos from '../application/components/Photos';
import Program from '../application/components/Program/Program';
import Projects from '../application/components/Project/Projects';
import Speakers from '../application/components/Speakers';
const Sticky = require('sticky-js');

// Stylesheets
import '../application/stylesheets/application.scss';

// Components
Vue.use(VueAwesomeSwiper);
Vue.use(VueScrollTo);
Vue.component('header-wrap', Header);
Vue.component('organizers', Organizers);
Vue.component('past-streams', PastStreams);
Vue.component('photos', Photos);
Vue.component('program', Program);
Vue.component('projects', Projects);
Vue.component('speakers', Speakers);

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Bootstrap all public admin goodies
require('../admin/public/bootstrap');

// Bootstrap the Vue root instance
document.addEventListener('turbolinks:load', () => {
  new Vue({ el: '#js-wrapper' });

  new Sticky('.secondary-menu');
});
