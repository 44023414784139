<template>
  <div :class="{ 'pb-20 sm:pb-24': !activeBio.id }">
    <SwiperSlider :options="options" ref="slider">
      <slot :showBio="showBio" :activeBioId="activeBio.id"></slot>
    </SwiperSlider>

    <div v-if="activeBio.id" class="speaker-bio relative">
      <button
        @click="activeBio.id = activeBio.bio = null"
        class="absolute right-0 top-0 mr-10 mt-10"
      >
        <img src="../../../assets/images/close.svg" alt="Close bio" />
      </button>

      <div v-if="activeBio.bio" v-html="activeBio.bio" />
      <div v-else class="container">
        <div class="text-sm text-white py-4">Loading...</div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperSlider from './SwiperSlider';

function calculateOffset() {
  return document.querySelector('#speakers .container h2').getBoundingClientRect().x;
}

export default {
  components: { SwiperSlider },

  watch: {
    activeBio: {
      deep: true,
      handler(activeBio) {
        const autoplay = this.$refs.slider.swiper().autoplay;
        // Pause the autoplay when a speaker is selected
        activeBio.id ? autoplay.stop() : autoplay.start();
      }
    }
  },

  data() {
    return {
      activeBio: {
        id: null,
        bio: null
      },
      loadedBios: {},
      options: {
        slidesOffsetBefore: calculateOffset(),
        slidesOffsetAfter: 60,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        }
      }
    };
  },

  methods: {
    async showBio(id) {
      this.activeBio.id = id;
      this.activeBio.bio = await this.fetchBio();
    },

    async fetchBio() {
      const id = this.activeBio.id;

      return new Promise(async (resolve, reject) => {
        if (!this.loadedBios[id]) {
          this.loadedBios[id] = await (await fetch(`/bios/${id}`)).text();
        }

        resolve(this.loadedBios[id]);
      });
    }
  }
};
</script>
