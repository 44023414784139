<template>
  <button class="project" @click="$emit('project-selected', project)">
    <div class="project-image" v-html="project.image"></div>
    <div class="project-info">
      <h3 class="project-title">{{ project.title }}</h3>
      <p class="project-countries">{{ project.countries }}</p>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    project: { required: true, type: Object }
  }
};
</script>
