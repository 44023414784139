<template>
  <div v-if="scheduleHtml" class="program-schedule" v-html="scheduleHtml" />
  <div v-else class="program-schedule text-sm py-4 min-h-screen">Loading...</div>
</template>

<script>
export default {
  props: {
    day: { required: true, type: Object }
  },

  data() {
    return { scheduleHtml: null };
  },

  async mounted() {
    // Load the schedule
    const scheduleData = await fetch(`/program-schedule/${this.day.id}`);
    this.scheduleHtml = await scheduleData.text();
  }
};
</script>
