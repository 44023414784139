<template>
  <div class="organizers">
    <OrganizersGroup
      v-for="organizers in groups"
      :organizers="organizers"
      :key="groupKey(organizers)"
      :active-organizer="activeOrganizer"
      @organizer-selected="activeOrganizer = $event"
    />
  </div>
</template>

<script>
import OrganizersGroup from './OgranizersGroup';
import chunk from 'lodash/chunk';
import find from 'lodash/find';

const breakpoints = [
  { width: 1280, cols: 5 },
  { width: 1024, cols: 4 },
  { width: 768, cols: 3 },
  { width: 580, cols: 2 },
  { width: 0, cols: 1 }
];

export default {
  components: { OrganizersGroup },

  props: {
    organizers: { required: true, type: Array }
  },

  data() {
    return {
      activeOrganizer: null,
      groups: []
    };
  },

  async mounted() {
    this.groupOrganizers();

    window.addEventListener('resize', this.groupOrganizers);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.groupOrganizers);
  },

  methods: {
    groupOrganizers() {
      this.groups = chunk(this.organizers, this.breakpoint().cols);
    },

    breakpoint() {
      return find(breakpoints, (item) => window.innerWidth >= item.width);
    },

    groupKey(organizers) {
      return organizers.map((item) => item.id).join('--');
    }
  }
};
</script>
