<template>
  <div class="relative">
    <swiper :options="finalOptions" ref="swiper">
      <slot></slot>
    </swiper>

    <button :id="prevBtnID" class="swiper-button-prev" slot="button-prev">
      <img src="../../../assets/images/left.svg" alt="Previous" />
    </button>
    <button :id="nextBtnID" class="swiper-button-next" slot="button-next">
      <img src="../../../assets/images/right.svg" alt="Next" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    options: { required: false, type: Object, default: () => ({}) }
  },

  computed: {
    uid() {
      return this._uid;
    },

    prevBtnID() {
      return `slider${this.uid}-prev`;
    },

    nextBtnID() {
      return `slider${this.uid}-next`;
    },

    finalOptions() {
      const defaultOptions = {
        navigation: {
          nextEl: `#${this.nextBtnID}`,
          prevEl: `#${this.prevBtnID}`
        },
        slidesPerView: 'auto',
        spaceBetween: 30
      };

      return { ...defaultOptions, ...this.options };
    }
  },

  methods: {
    swiper() {
      return this.$refs.swiper.$swiper;
    }
  }
};
</script>
