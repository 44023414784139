<template>
  <div>
    <div class="project-categories">
      <slot
        name="categories"
        :activeCategory="activeCategory"
        :toggleActiveCategory="toggleActiveCategory"
      />
    </div>
    <div class="projects">
      <keep-alive>
        <List :category="activeCategory" :key="activeCategory ? activeCategory : 'all'" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import List from './List';

export default {
  components: { List },

  props: {
    defaultCategory: { required: false, type: String }
  },

  data() {
    return {
      activeCategory: this.defaultCategory
    };
  },

  methods: {
    toggleActiveCategory(category) {
      this.activeCategory = this.activeCategory !== category ? category : null;
    }
  }
};
</script>
