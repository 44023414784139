<template>
  <SwiperSlider class="past-streams-slider">
    <slot></slot>
  </SwiperSlider>
</template>

<script>
import SwiperSlider from './SwiperSlider';

export default {
  components: { SwiperSlider }
};
</script>
