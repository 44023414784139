<template>
  <div class="program">
    <Days :days="days" v-model="currentDay" />
    <keep-alive>
      <Schedule :day="currentDay" :key="currentDay.id" />
    </keep-alive>
  </div>
</template>

<script>
import Days from './Days';
import Schedule from './Schedule';

export default {
  components: { Days, Schedule },

  props: {
    days: { required: true, type: Array },
    initialCurrent: { required: true, type: Object }
  },

  data() {
    return {
      currentDay: this.initialCurrent
    };
  }
};
</script>
