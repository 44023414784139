<template>
  <div class="organizer-details">
    <button class="organizer-details-close" @click="$emit('close')">
      <img src="../../../../assets/images/close.svg" alt="Close details" />
    </button>
    <div class="container">
      <h3 class="organizer-details-name">
        <a
          :href="organizer.link"
          v-if="organizer.link"
          rel="nofollow noreferrer"
          target="_blank"
          class="underline"
        >
          {{ organizer.name }}
          <img
            src="../../../../assets/images/external.svg"
            class="inline ml-4"
            alt="External link"
          />
        </a>
        <template v-else>{{ organizer.name }}</template>
      </h3>
      <div class="organizer-details-body readactorred" v-html="organizer.body" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    organizer: { required: true, type: Object }
  }
};
</script>
