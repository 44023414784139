<template>
  <div class="photos-slider" :class="{ 'pb-20 sm:pb-24': !activePhoto }">
    <SwiperSlider :options="options" ref="slider">
      <SwiperSlide v-for="photo in photos" :key="photo.id">
        <button
          @click.prevent="toggleActive(photo)"
          :class="{ active: activePhoto && activePhoto.id === photo.id }"
          class="photo-slider-photo"
          v-html="photo.image"
        />
      </SwiperSlide>
    </SwiperSlider>

    <div v-if="activePhoto" class="photo-details relative">
      <button @click="activePhoto = null" class="absolute right-0 top-0 mr-10 mt-10">
        <img src="../../../assets/images/close.svg" alt="Close bio" />
      </button>

      <div class="photo-details-photo space-y-4">
        <div class="photo-container" v-html="activePhoto.original"></div>
        <h4 class="font-bold text-2xl sm:text-3xl text-white" v-if="activePhoto.title">
          {{ activePhoto.title }}
        </h4>
        <div
          class="text-opacity-75 text-white readactorred"
          v-if="activePhoto.body"
          v-html="activePhoto.body"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperSlider from './SwiperSlider';

export default {
  components: { SwiperSlider },

  props: {
    photos: { required: true, type: Array }
  },

  watch: {
    activePhoto(activePhoto) {
      const autoplay = this.$refs.slider.swiper().autoplay;
      // Pause the autoplay when a speaker is selected
      activePhoto ? autoplay.stop() : autoplay.start();
    }
  },

  data() {
    return {
      activePhoto: null,
      options: Object.freeze({
        spaceBetween: 0,
        slidesOffsetBefore: 60,
        slidesOffsetAfter: 60,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        }
      })
    };
  },

  methods: {
    toggleActive(photo) {
      if (this.activePhoto && this.activePhoto.id === photo.id) {
        this.activePhoto = null;
      } else {
        this.activePhoto = photo;
      }
    }
  }
};
</script>
