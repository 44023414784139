<template>
  <div class="project-details">
    <button class="project-details-close" @click="$emit('close')">
      <img src="../../../../assets/images/close.svg" alt="Close details" />
    </button>
    <div class="container">
      <h3 class="project-details-title">
        <a
          :href="project.link"
          v-if="project.link"
          rel="nofollow noreferrer"
          target="_blank"
          class="underline"
        >
          {{ project.title }}
          <img
            src="../../../../assets/images/external.svg"
            class="inline ml-4"
            alt="External link"
          />
        </a>
        <template v-else>{{ project.title }}</template>
      </h3>
      <p class="project-details-countries">{{ project.countries }}</p>
      <div class="project-details-body readactorred" v-html="project.body" />

      <div v-if="attachments.length" class="pt-8">
        <Attachment
          v-for="attachment in attachments"
          :key="attachment.id"
          :attachment="attachment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Attachment from './Attachment';

export default {
  components: { Attachment },

  props: {
    project: { required: true, type: Object }
  },

  computed: {
    attachments() {
      const attachements = [
        this.project.attachment1,
        this.project.attachment2,
        this.project.attachment3
      ];

      return attachements.filter((attachment) => !!attachment);
    }
  }
};
</script>
