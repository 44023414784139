<template>
  <div class="program-days">
    <button
      v-for="day in days"
      :key="day.id"
      :class="{ active: day.id === currentDay.id }"
      @click="$emit('input', day)"
      class="program-day"
    >
      <div class="program-day-title">{{ day.title }}</div>
      <div class="program-day-date">{{ day.short_date }}</div>
    </button>
  </div></template
>

<script>
export default {
  props: {
    days: { required: true, type: Array },
    value: { required: true, type: Object }
  },

  computed: {
    currentDay() {
      return this.value;
    }
  }
};
</script>
