<template>
  <div class="mb-6">
    <a :href="attachment.location" class="text-white hover:underline">
      <img
        src="../../../../assets/images/download-light.svg"
        class="inline-block mr-2"
        alt="Download"
      />
      <span>{{ attachment.name }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    attachment: { required: true, type: Object }
  }
};
</script>
